import React, { Component } from 'react';
import fetch from 'cross-fetch'
import { HashRouter as Router, Route, Link, Redirect } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import configuration from '../configuration';
import Moment from "react-moment";
import EditAlarmModal from './EditAlarmModal'
import {
  Container,
  Divider,
  Dropdown,
  Header,
  Image,
  Input,
  Menu,
  Responsive,
  Sidebar,
  Step,
  StepProps,
  Table,
  Grid,
  Form,
  Icon,
  Button,
  Segment
} from 'semantic-ui-react'

class Alarms extends Component {
  constructor(props) {
    super(props);
    //this.state = {UserName: '',PassWord: '', usernametable:[]};
    this.state = {
      login: null,
      loading: false,
      items: []
    };

    this.login = this.login.bind(this);
    this.getItems = this.getItems.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    //let logindata = await this.login("Admin", "Test123#");
    //this.setState({ login: logindata });

    // let itemit = await this.getItems();
    // this.setState({ items: itemit });
    this.getItems();

    this.interval = setInterval(() => {
      this.getItems();

    }, 5000);

  }

  login(username, password) {
    let logincredentials = {
      "UserName": username,
      "Password": password
    };

    let self = this;

    return fetch(configuration.AUTH_URL, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(logincredentials)
    }).then(function (response) {
      return response.json();
    }).then(function (data) {

      console.log("Success:", data);
      //self.setState({ login: data });

      return data;

    }).catch(function (error) {
      console.log("Virhe:", error);
    });
  }

  onRowClick(itemid) {
    // console.log("Valittu item:", itemid);
    // this.setState({ selecteditem: itemid }, () => {

    //   this.getLocations();

    // });
  }
  //
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getItems() {
    //e.preventDefault();

    let self = this;

    fetch(configuration.ROOT_URL + '/Web/getalarms/', {
      headers: {
        'Content-Type': 'application/json',
        'credentials': 'include'
      },
      method: 'GET'
    }).then(function (response) {
      return response.json();
    }).then(function (data) {

      console.log("Success:", data);
      self.setState({ items: data });

    }).catch(function (error) {
      console.log("Virhe:", error);
    });
  }

  getStatusText(status) {
    try {
      switch (status) {
        case 0:
          return <Table.Cell negative>
            <Header as='h4' image><Header.Content><Icon name='attention' />ERROR</Header.Content></Header>
          </Table.Cell>
        case 1:
          return <Table.Cell positive>
            <Header as='h4' image><Header.Content>INFO</Header.Content></Header>
          </Table.Cell>
        default:
          return <Table.Cell>
            <Header as='h4' image><Header.Content>UNKNOWN</Header.Content></Header>
          </Table.Cell>
      }


    } catch (error) {
      console.log("Virhe:", error);

    }
  }

  getStatusColor(status) {
    try {
      switch (status) {
        case 1:
          return 'positive'
        case 0:
          return 'negative'
        default:
          return ''
      }
    } catch (error) {
      console.log("Virhe:", error);

    }
  }

  formatDate(timestamp) {
    try {
      if (timestamp == null) return "";

      var date = new Date(timestamp);

      if (date.getFullYear() < 2000) return "";

      return (<Moment locale="fi" format="DD.MM.YYYY HH.mm">{date}</Moment>);

    } catch (error) {
      console.log("Virhe:", error);

    }
  }

  render() {
    //const { selecteditem } = this.state;
    /* if (this.state.login == null) {
      return <Redirect to='/login' />
    }

    if (this.state.login !== null) {
      return <Redirect to='/alarmlist' />
    } */

    return (
      <Router>
        <div className="Alarms" style={{width: '95%', marginLeft:'23px'}}>

          
            

                <Table selectable compact striped >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>AlarmId</Table.HeaderCell>
                      <Table.HeaderCell>Description</Table.HeaderCell>
                      <Table.HeaderCell>SubSystem</Table.HeaderCell>
                      <Table.HeaderCell>ExternalReference</Table.HeaderCell>
                      <Table.HeaderCell>EventType</Table.HeaderCell>
                      <Table.HeaderCell>TimeStamp</Table.HeaderCell>
                      <Table.HeaderCell>FixedBy</Table.HeaderCell>
                      <Table.HeaderCell>TimeFixed</Table.HeaderCell>
                      <Table.HeaderCell>FixedComment</Table.HeaderCell>
                      <Table.HeaderCell>EmailRecipients</Table.HeaderCell>
                      <Table.HeaderCell>TimeEmailSent</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.items.map((item, Id) => {
                      return <Table.Row key={Id} onClick={() => this.onRowClick(item.Id)} >
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          <EditAlarmModal
                            Id={item.Id.toString()}></EditAlarmModal>
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          <Header as='h4' image><Header.Content>{item.AlarmId}</Header.Content></Header>
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {item.Description}
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {item.SubSystem}
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {item.ExternalReference}
                        </Table.Cell>
                        {this.getStatusText(item.EventType)}
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {this.formatDate(item.TimeStamp)}
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {item.FixedBy}
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {this.formatDate(item.TimeFixed)}
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {item.FixedComment}
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {item.EmailSentRecipients}
                        </Table.Cell>
                        <Table.Cell className={item.EventType === 0 && "negative"} >
                          {this.formatDate(item.TimeEmailSent)}
                        </Table.Cell>
                      </Table.Row>;
                    })}
                  </Table.Body>
                </Table>

        </div>
      </Router>
    );
  }
}

export default Alarms;

import React, { Component } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import SystemMonitors from './Components/SystemMonitors';
import Alarms from './Components/Alarms';
import Settings from './Components/Settings';
import ApiCall from './api/ApiCall';
import Login from './/Login';
import configuration from './configuration';

import {
  Menu,
  Segment,
  Image,
  Icon
} from 'semantic-ui-react'

class App extends Component {
  state = { loading: false, activeItem: 'Monitoring' }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  submitLogout = async () => {
    this.setState({ loading: true }, async () => {

      let result = await new ApiCall().logout();
      // if(typeof result === "string"){
      //     console.error(result);
      //     this.setState({ error: "Logout failed", loading:false });
      // }
      // else {
      //     let toUrl = result && result.Roles && result.Roles.includes("Admin")  ? "/" : "/";
      this.setState({ loading: false }, () => this.props.history.push("/login"));
      //}
    });
  }

  getUserName = () => {
    try {
      var loginResultString = window.localStorage.getItem(configuration.localStorageKey);

      var loginResultJson = window.JSON.parse(loginResultString);

      return "(" + loginResultJson.UserName + ") Logout";

    } catch (error) {
      return "Logout";
    }
  }

  render() {
    const { activeItem } = this.state

    return (
      <div style={{width: '100%'}}>
        <Menu pointing secondary>
          <Menu.Item><h4 >ATTUNE Cloud Monitor</h4></Menu.Item>
          <Menu.Item
            name='Monitoring'
            active={activeItem === 'Monitoring'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Alarms'
            active={activeItem === 'Alarms'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Settings'
            active={activeItem === 'Settings'}
            onClick={this.handleItemClick}
          />
          <Menu.Menu position='right'>
            <Menu.Item
              name='Logout'
              content={this.getUserName()}
              active={activeItem === 'Logout'}
              onClick={this.submitLogout}
            />
          </Menu.Menu>
        </Menu>


        {(() => {

          switch (this.state.activeItem) {
            case 'Monitoring':
              return <SystemMonitors />;
            case 'Alarms':
              return <Alarms />;
            case 'Settings':
              return <Settings />;
            case 'Logout':
              return <Login />;
            default:
              return "<Login />";
          }
        })()}

      </div>
    )
  }
}

export default App;

import React, { Component } from 'react';
import fetch from 'cross-fetch'
import { HashRouter as Router } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import configuration from '../configuration';
import Moment from "react-moment";
import '../App.css';
import EditSettingModal from './EditSettingModal'
import {
  Container,
  Header,
  Table,
  Grid,
  Icon,
  Button,
  Popup,
  Input,
  Checkbox
} from 'semantic-ui-react'

class Settings extends Component {
  constructor(props) {
    super(props);
    //this.state = {UserName: '',PassWord: '', usernametable:[]};
    this.state = {
      login: null,
      loading: false,
      items: [],
      pagestatus: true,
      newfixedcomment: ""
    };

    this.login = this.login.bind(this);
    this.getItems = this.getItems.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    //let logindata = await this.login("Admin", "Test123#");
    //this.setState({ login: logindata });

    // let itemit = await this.getItems();
    // this.setState({ items: itemit });
    this.getItems();

    this.interval = setInterval(() => {
      this.getItems();

    }, 5000);

  }

  login(username, password) {
    let logincredentials = {
      "UserName": username,
      "Password": password
    };

    return fetch(configuration.AUTH_URL, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(logincredentials)
    }).then(function (response) {
      return response.json();
    }).then(function (data) {

      console.log("Success:", data);
      //self.setState({ login: data });

      return data;

    }).catch(function (error) {
      console.log("Virhe:", error);
    });
  }

  onRowClick(itemid) {
    // console.log("Valittu item:", itemid);
    // this.setState({ selecteditem: itemid }, () => {

    //   this.getLocations();

    // });
  }
  //

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }


  getItems() {
    //e.preventDefault();

    let self = this;

    fetch(configuration.ROOT_URL + '/Setting/', {
      headers: {
        'Content-Type': 'application/json',
        'credentials': 'include'
      },
      method: 'GET'
    }).then(function (response) {
      return response.json();
    }).then(function (data) {

      console.log("getItems Success:", data);
      self.setState({ items: data });

    }).catch(function (error) {
      console.log("getItems Virhe:", error);
    });
  }

  formatDate(timestamp) {
    try {
      if (timestamp == null) return "";

      var date = new Date(timestamp);

      if (date.getFullYear() === 0) return "";

      return (<Moment locale="fi" format="DD.MM.YYYY HH.mm">{date}</Moment>);

    } catch (error) {
      console.log("Virhe:", error);

    }
  }


  render() {

    return (
      <Router>
        <div className="Settings" style={{width: '95%', marginLeft:'23px'}}>

                <Table selectable compact striped >
                  <Table.Header>
                    <Table.Row >
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>Id</Table.HeaderCell>
                      <Table.HeaderCell>Enabled</Table.HeaderCell>
                      <Table.HeaderCell>SettingName</Table.HeaderCell>
                      <Table.HeaderCell>SettingType</Table.HeaderCell>
                      <Table.HeaderCell>MinValue</Table.HeaderCell>
                      <Table.HeaderCell>MaxValue</Table.HeaderCell>
                      <Table.HeaderCell>ErrorTimeMinutes</Table.HeaderCell>
                      <Table.HeaderCell>EmailDelayMinutes</Table.HeaderCell>
                      <Table.HeaderCell>Description</Table.HeaderCell>
                      <Table.HeaderCell>CompanyId</Table.HeaderCell>
                      <Table.HeaderCell>EmailRecipients</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.items.map((item, Id) => {
                      return <Table.Row key={Id} onClick={() => this.onRowClick(item.Id)}>
                        <Table.Cell >
                          <EditSettingModal
                            Id={item.Id.toString()}></EditSettingModal>
                        </Table.Cell>
                        <Table.Cell >
                          <Header as='h4' ><Header.Content>{item.Id}</Header.Content></Header>
                        </Table.Cell>
                        <Table.Cell >
                          <Checkbox checked={item.Enabled}></Checkbox>

                        </Table.Cell>
                        <Table.Cell >
                          {item.SettingName}
                        </Table.Cell>
                        <Table.Cell >
                          {item.SettingType}
                        </Table.Cell>
                        <Table.Cell >
                          {item.MinValue}
                        </Table.Cell>
                        <Table.Cell >
                          {item.MaxValue}
                        </Table.Cell>
                        <Table.Cell >
                          {item.ErrorTimeMinutes}
                        </Table.Cell>
                        <Table.Cell >
                          {item.EmailDelayMinutes}
                        </Table.Cell>
                        <Table.Cell >
                          {item.Description}
                        </Table.Cell>
                        <Table.Cell >
                          {item.CompanyId}
                        </Table.Cell>
                        <Table.Cell >
                          {item.EmailRecipients}
                        </Table.Cell>
                      </Table.Row>;
                    })}
                  </Table.Body>
                </Table>
        </div>
      </Router>
    );
  }
}

export default Settings;

import React, { Component } from 'react'
import { Button, Header, Icon, Modal, Popup } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import configuration from '../configuration';
import './../App.css';
import EditSystemMonitorForm from './EditSystemMonitorForm'

class EditSystemMonitorModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            edititem: "",
            Error: ""
        };

    }

    handleOpen = () => this.setState({ modalOpen: true })
    handleClose = () => this.setState({ modalOpen: false })

    getSystemMonitor() {
        //e.preventDefault();

        let self = this;

        fetch(configuration.ROOT_URL + '/SystemMonitor/' + this.props.Id, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then(function (data) {

            console.log("Success:", data);
            self.setState({ edititem: data });

        }).catch(function (error) {
            console.log("Virhe:", error);
        });
    }

    putSystemMonitor(item) {
        let self = this;

        fetch(configuration.ROOT_URL + '/SystemMonitor/' + item.Id, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            method: 'PUT',
            body: JSON.stringify({
                "Enabled": item.Enabled,
                "SettingName": item.SettingName,
                "SettingId": item.SettingId,
                "MonitorId": item.MonitorId,
                "UserId": item.UserId,
                "CompanyId": item.CompanyId,
                "UserName": item.UserName,
                "TimeStamp": new  Date(),
                "ErrorTimeMinutes": item.ErrorTimeMinutes,
                "Status": item.Status,
                "MinValue": item.MinValue,
                "CurrentValue": item.CurrentValue,
                "MaxValue": item.MaxValue,
                "SubSystem": item.SubSystem,
                "Description": item.Description,
                "ExternalReference": item.ExternalReference,
                "FixedBy": item.FixedBy,
                "FixedComment": item.FixedComment,
                "TimeFixed": item.TimeFixed,
                "EmailSentRecipients": item.EmailSentRecipients,
                "TimeEmailSent": item.TimeEmailSent,
                "Id": item.Id
              })
        }).then(function (response) {
            return response.json();
        }).then(function (data) {

            self.setState({ modalOpen: false });
            console.log("Success:", data);

        }).catch(function (error) {
            self.setState({ Error: error });
            console.log("Virhe:", error);
        });
    }

    updateData = (systemmonitor) => {
        try {
            console.log("Update item:" + systemmonitor.Id);

            this.setState({
                edititem: systemmonitor
            }, () => {
                
            });

        } catch (error) {
            console.log(error);
        }
    }

    deleteSystemMonitor = (item) => {
        let self = this;

        try {
            if (item.Id === undefined || item.Id <= 0) return;

            console.log("Delete item " + item.Id)

            fetch(configuration.ROOT_URL + '/SystemMonitor/' + item.Id, {
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'include'
                },
                method: 'DELETE',
            }).then(function (response) {    
                self.setState({ modalOpen: false });
                console.log("deleteSystemMonitor Success:");
    
            }).catch(function (error) {
                self.setState({ Error: error });
                console.log("deleteSystemMonitor Virhe:", error);
            });
        }
        catch (e) {
            console.log(e);
        }


    }

    render() {
        return (
            <Modal
                trigger={<Icon name='edit' onClick={this.handleOpen} title="Edit" />}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size='large'
                closeIcon
            >
                <Header icon='edit' content={"SystemMonitor Id:" + this.props.Id} />
                <Modal.Content>
                    {<EditSystemMonitorForm Id={this.props.Id} Error={this.state.Error} UpdateCallback={this.updateData} />}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={() => this.putSystemMonitor(this.state.edititem)} inverted>
                        <Icon name='checkmark' /> Save
              </Button>
                    <Popup
                        trigger={
                            <Button color='red' inverted>
                                <Icon name='delete' /> Delete</Button>}
                        content={<Button color='green' content='Confirm the delete' onClick={() => this.deleteSystemMonitor(this.state.edititem)} />}
                        on='click'
                        position='top right'
                    />

                    <Button color='blue' onClick={this.handleClose} inverted>
                        <Icon name='window close outline' /> Close
              </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}

export default EditSystemMonitorModal;
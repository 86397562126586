import React, { Component } from 'react'
import './../App.css';
import configuration from '../configuration';
import { Form, Label, Segment, Grid, Message } from 'semantic-ui-react'
import moment from 'moment';

class EditAlarmForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            edititem: undefined,
            Error: ""
        };

        this.getAlarm = this.getAlarm.bind(this);
        this.formatStringDate = this.formatStringDate.bind(this);

    }

    componentDidMount() {
        this.getAlarm();
    }

    getAlarm() {
        //e.preventDefault();

        let self = this;

        fetch(configuration.ROOT_URL + '/Alarm/' + this.props.Id, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then(function (data) {

            console.log("Success:", data);
            //self.setState({ edititem: data });
            self.setState((state, props) => {
                return { edititem: data };
            }, () => {
                self.props.UpdateCallback(self.state.edititem);
            });

        }).catch(function (error) {
            console.log("Virhe:", error);
        });
    }

    formatStringDate(datestr) {
        try {
            var date = new Date(datestr);

            return date.toDateString();
        } catch (error) {
            return datestr;
        }
    }

    defaultDate = (d) =>
        d === null ? moment().format("YYYY-MM-DD") : moment(d).format("YYYY-MM-DD");

    onchange = (key, value) => {
        this.setState((state, props) => {
            return { edititem: { ...state.edititem, [key]: value } };
        }, () => {
            this.props.UpdateCallback(this.state.edititem);
        });
    }

    handleChange = (e, data) => {
        e.persist();
        console.log(e);
        this.setState((state, props) => {
            return { edititem: { ...state.edititem, [e.target.name]: e.target.value } };
        }, () => {
            this.props.UpdateCallback(this.state.edititem);
        });
    };

    putFixed = () => {
        try {
            var loginResultString = window.localStorage.getItem(configuration.localStorageKey);

            var loginResultJson = window.JSON.parse(loginResultString);

            console.log(loginResultJson); //?

            console.log(loginResultJson.user); //?

            this.setState((state, props) => {
                return { edititem: { ...state.edititem, 'FixedBy': loginResultJson.UserName, 'TimeFixed': new Date(), 'EmailSentRecipients': null, 'TimeEmailSent': '0001-01-01T00:00:00.000000+00:00' } };
            }, () => {
                this.props.UpdateCallback(this.state.edititem);
            });

        } catch (error) {

        }
    }

    getFixedText = () => {
        try {
            if (this.state.edititem == null || this.state.edititem == undefined) return "";

            let retval = this.state.edititem.TimeFixed + "/" + this.state.edititem.FixedBy

            return retval;

        } catch (error) {
            return "";
        }
    }

    render() {

        return (
            <Form error={this.props.Error !== ""}>
                <Grid columns={1}>
                    <Grid.Column>
                        <Segment raised>

                            <Form.Input
                                className="borderless"
                                readOnly
                                inline
                                fluid
                                label="Id:"
                                placeholder="Id"
                                name="Id"
                                value={!!this.state.edititem ? this.state.edititem.Id : ""}
                            />

                            <Form.Input
                                inline
                                fluid
                                label="SubSystem:"
                                placeholder="SubSystem"
                                name="SubSystem"
                                value={!!this.state.edititem ? this.state.edititem.SubSystem : ""}
                                onChange={this.handleChange}
                            />

                            <Form.Input
                                inline
                                fluid
                                label="Description:"
                                placeholder="Description"
                                name="Description"
                                value={!!this.state.edititem ? this.state.edititem.Description : ""}
                                onChange={this.handleChange}
                            />

                            <Form.Input
                                inline
                                fluid
                                label="ExternalReference:"
                                placeholder="ExternalReference"
                                name="ExternalReference"
                                value={!!this.state.edititem ? this.state.edititem.ExternalReference : ""}
                                onChange={this.handleChange}
                            />

                            <Form.Button color="green" onClick={() => this.putFixed()} >Fixed</Form.Button>
                            <Label>{this.getFixedText()}</Label>
                            <br></br>
                            <Form.Input
                                inline
                                fluid
                                label="FixedComment:"
                                placeholder="FixedComment"
                                name="FixedComment"
                                value={!!this.state.edititem ? this.state.edititem.FixedComment : ""}
                                onChange={this.handleChange}
                            />

                        </Segment>
                    </Grid.Column>
                </Grid>
                <Message
                    error
                    header="Error saving alarm"
                    content={this.props.Error}
                />
            </Form>
        );
    }
}

export default EditAlarmForm;
import React, { Component } from 'react';
import { Router, Route } from "react-router-dom";
import App from './App';
import history from "./helpers/History";
import Login from './/Login';
import ApiCall from './api/ApiCall';
import History from './helpers/History';
import configuration from './configuration';


class Routes extends Component {
  
  constructor(props){
    super(props);
    this.loginInterval = null;
  }
  /***
   * Setup automatic relogin => refresh token automatically in intervall
   * Setup route change listener, if new route that is not login then set  auto login interval
   */
  componentDidMount(){
    //On first load
    this.setupAutomaticRelogin();
    History.listen((location,action) => {
      //When url changes
      if(History && History.location && History.location.pathname !== "/login"){
       
        if(this.loginInterval && this.loginInterval !== null) window.clearInterval(this.loginInterval);

        this.loginInterval = window.setInterval(() => {this.login() }, 3600000); //Relogin every 60 minutes automatic
      }
    });
  }
  /***
   * Setup automatic refresh of token, so that login will persist
   */
  setupAutomaticRelogin(){
      //Clear any current login intervals, if exists
      if(this.loginInterval && this.loginInterval !== null) window.clearInterval(this.loginInterval);

      //If current page is not "/login" page, then try to login
      if(History && History.location && History.location.pathname !== "/login"){
        this.login(); //Relogin on every page

        //Setup interval to relogin, so that the token will update itself
        this.loginInterval = window.setInterval(() => {
            this.login()
        }, 3600000); //Relogin every 60 minutes automatic

      }
  }
  /***
   * Login with existing token
   */
  async login(){
    //Try to login with existing cookie automatically
    let result = await new ApiCall(true).login();
    if(typeof result === "string")
    {
      //Move to login, if not allready in loginpage
      if(History && History.location && History.location.pathname !== "/login")
        History.push("/login"); //Move to login
    }
    else if(History && History.location && History.location.pathname === "/login")
        History.push("/");

  }
  /**
   * Check if user is valid Admin
   */
  isAdmin = () => {
    //Get from localstorage
    let userdata = window.localStorage.getItem(configuration.localStorageKey);

    if(userdata === null || userdata === undefined) 
      return false;

    let user = window.JSON.parse(userdata);

    if(user === null || user === undefined) 
      return false;

    else if(user.Roles){
        if(user.Roles.includes("Admin") === false) return false;
        else 
          return true;
    }
    else {
        return false;
    }

    
    
}
  render(){
      return(
        <Router history={history}>
            <Route path="/login" exact component={Login} />
            <Route path="/" exact component={App}/>
        </Router>);

    }
}
  
  
export default Routes;

const configuration = {
    AUTH_URL: process.env.REACT_APP_AUTH_URL,
    ROOT_URL: process.env.REACT_APP_ROOT_URL,
    localStorageKey: 'ss-attune-id'
};
/* if freeze is supported */
if(typeof Object !== "undefined" && !!Object.freeze){
    Object.freeze(configuration);
}

export default configuration;
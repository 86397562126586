import React, { Component } from 'react'
import { Button, Header, Icon, Modal, Popup } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import configuration from '../configuration';
import './../App.css';
import EditSettingForm from './EditSettingForm'

class EditSettingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            edititem: "",
            Error: ""
        };

    }

    handleOpen = () => this.setState({ modalOpen: true })
    handleClose = () => this.setState({ modalOpen: false })

    getSetting() {
        //e.preventDefault();

        let self = this;

        fetch(configuration.ROOT_URL + '/Setting/' + this.props.Id, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then(function (data) {

            console.log("Success:", data);
            self.setState({ edititem: data });

        }).catch(function (error) {
            console.log("Virhe:", error);
        });
    }

    putSetting(item) {
        let self = this;

        fetch(configuration.ROOT_URL + '/Setting/' + item.Id, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            method: 'PUT',
            body: JSON.stringify(
                {
                    "SettingName": item.SettingName,
                    "SettingType": item.SettingType,
                    "MinValue": item.MinValue,
                    "MaxValue": item.MaxValue,
                    "ErrorTimeMinutes": item.ErrorTimeMinutes,
                    "EmailDelayMinutes": item.EmailDelayMinutes,
                    "Description": item.Description,
                    "CompanyId": item.CompanyId,
                    "EmailRecipients": item.EmailRecipients,
                    "Enabled": item.Enabled,
                    "Id": item.Id
                  })
        }).then(function (response) {
            return response.json();
        }).then(function (data) {

            self.setState({ modalOpen: false });
            console.log("Success:", data);

        }).catch(function (error) {
            self.setState({ Error: error });
            console.log("Virhe:", error);
        });
    }

    updateData = (setting) => {
        try {
            console.log("Update item:" + setting.Id);

            this.setState({
                edititem: setting
            }, () => {

            });

        } catch (error) {
            console.log(error);
        }
    }

    deleteSetting = (item) => {
        let self = this;

        try {
            if (item.Id === undefined || item.Id <= 0) return;

            console.log("Delete item " + item.Id)

            fetch(configuration.ROOT_URL + '/Setting/' + item.Id, {
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'include'
                },
                method: 'DELETE',
            }).then(function (response) {
                self.setState({ modalOpen: false });
                console.log("deleteSetting Success:");

            }).catch(function (error) {
                self.setState({ Error: error });
                console.log("deleteSetting Virhe:", error);
            });
        }
        catch (e) {
            console.log(e);
        }


    }

    render() {
        return (
            <Modal
                trigger={<Icon name='edit' onClick={this.handleOpen} title="Edit" />}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size='large'
                closeIcon
            >
                <Header icon='edit' content={"Setting Id:" + this.props.Id} />
                <Modal.Content>
                    {<EditSettingForm Id={this.props.Id} Error={this.state.Error} UpdateCallback={this.updateData} />}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={() => this.putSetting(this.state.edititem)} inverted>
                        <Icon name='checkmark' /> Save
              </Button>
                    <Popup
                        trigger={
                            <Button color='red' inverted>
                                <Icon name='delete' /> Delete</Button>}
                        content={<Button color='green' content='Confirm the delete' onClick={() => this.deleteSetting(this.state.edititem)} />}
                        on='click'
                        position='top right'
                    />

                    <Button color='blue' onClick={this.handleClose} inverted>
                        <Icon name='window close outline' /> Close
              </Button>
                </Modal.Actions>
            </Modal>
        )
    }

}

export default EditSettingModal;
import React, { Component } from 'react'
import './../App.css';
import configuration from '../configuration';
import { Form, Label, Segment, Grid, Message } from 'semantic-ui-react'
import moment from 'moment';

export const SettingType = {
    Alarm: 0,
    SystemMonitor: 1
}

export const getSettingTypeText = (settingtype) => {

    switch (settingtype) {
        case SettingType.Alarm:
            return 'Alarm';
        case SettingType.SystemMonitor:
            return 'SystemMonitor';
        default:
            return SettingType.toString();
    }
}

export const getSettingTypeDropdownOptions = () => {

    let options = [];
    for (var property in SettingType)
        options.push({
            key: 'settingtype-' + property,
            text: getSettingTypeText(SettingType[property]),
            value: SettingType[property]
        });

    return options;

}

class EditSettingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            edititem: undefined,
            Error: ""
        };

        this.getSetting = this.getSetting.bind(this);
        this.formatStringDate = this.formatStringDate.bind(this);

    }

    componentDidMount() {
        this.getSetting();
    }

    getSetting() {
        //e.preventDefault();

        let self = this;

        fetch(configuration.ROOT_URL + '/Setting/' + this.props.Id, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then(function (data) {

            console.log("Success:", data);
            //self.setState({ edititem: data });
            self.setState((state, props) => {
                return { edititem: data };
            }, () => {
                self.props.UpdateCallback(self.state.edititem);
            });

        }).catch(function (error) {
            console.log("Virhe:", error);
        });
    }

    handleChange = (e, data) => {
        e.persist();
        console.log(e);
        this.setState((state, props) => {
            return { edititem: { ...state.edititem, [e.target.name]: e.target.value } };
        }, () => {
            this.props.UpdateCallback(this.state.edititem);
        });
    };

    formatStringDate(datestr) {
        try {
            var date = new Date(datestr);

            return date.toDateString();
        } catch (error) {
            return datestr;
        }
    }

    defaultDate = (d) =>
        d === null ? moment().format("YYYY-MM-DD") : moment(d).format("YYYY-MM-DD");

    onchange = (key, value) => {
        this.setState((state, props) => {
            return { edititem: { ...state.edititem, [key]: value } };
        }, () => {
            this.props.UpdateCallback(this.state.edititem);
        });
    }

    render() {

        return (
            <Form error={this.props.Error !== ""}>
                <Grid columns={1}>
                    <Grid.Column>
                        <Segment raised>
                            <Form.Checkbox
                                inline
                                label="Enabled"
                                name="Enabled"
                                checked={this.state.edititem && this.state.edititem.Enabled}
                                onChange={(e, { checked }) => this.onchange('Enabled', checked)}
                            />
                            <Form.Input
                                className="borderless"
                                readOnly
                                inline
                                fluid
                                label="Id:"
                                placeholder="Id"
                                name="Id"
                                value={!!this.state.edititem ? this.state.edititem.Id : ""}
                            />

                            <Form.Input
                                inline
                                fluid
                                label="SettingName:"
                                placeholder="SettingName"
                                name="SettingName"
                                value={!!this.state.edititem ? this.state.edititem.SettingName : ""}
                                onChange={this.handleChange}
                            />

                            <Form.Select
                                label="Status:"
                                name="Status"
                                options={getSettingTypeDropdownOptions()}
                                value={this.state.edititem && this.state.edititem.SettingType}
                                onChange={(e, { value }) => this.onchange('SettingType', value)}
                            >
                            </Form.Select>

                            <Form.Input
                                inline
                                fluid
                                label="Description:"
                                placeholder="Description"
                                name="Description"
                                value={!!this.state.edititem ? this.state.edititem.Description : ""}
                                onChange={this.handleChange}
                            />

                            <Form.Input
                                inline
                                fluid
                                label="CompanyId:"
                                placeholder="CompanyId"
                                name="CompanyId"
                                value={!!this.state.edititem ? this.state.edititem.CompanyId : ""}
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                inline
                                fluid
                                label="EmailRecipients:"
                                placeholder="EmailRecipients"
                                name="EmailRecipients"
                                value={!!this.state.edititem ? this.state.edititem.EmailRecipients : ""}
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                inline
                                fluid
                                label="ErrorTimeMinutes:"
                                placeholder="ErrorTimeMinutes"
                                name="ErrorTimeMinutes"
                                type="number"
                                value={!!this.state.edititem ? this.state.edititem.ErrorTimeMinutes : ""}
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                inline
                                fluid
                                label="EmailDelayMinutes:"
                                placeholder="EmailDelayMinutes"
                                name="EmailDelayMinutes"
                                type="number"
                                value={!!this.state.edititem ? this.state.edititem.EmailDelayMinutes : ""}
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                inline
                                fluid
                                label="MinValue:"
                                placeholder="MinValue"
                                name="MinValue"
                                type="number"
                                value={!!this.state.edititem ? this.state.edititem.MinValue : ""}
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                inline
                                fluid
                                label="MaxValue:"
                                placeholder="MaxValue"
                                name="MaxValue"
                                type="number"
                                value={!!this.state.edititem ? this.state.edititem.MaxValue : ""}
                                onChange={this.handleChange}
                            />

                        </Segment>
                    </Grid.Column>
                </Grid>
                <Message
                    error
                    header="Error saving setting"
                    content={this.props.Error}
                />
            </Form>
        );
    }
}

export default EditSettingForm;
import React, { Component } from 'react'
import './../App.css';
import configuration from '../configuration';
import { Form, Label, Segment, Grid, Message } from 'semantic-ui-react'
import moment from 'moment';

export const MonitorState = {
    None: 0,
    Starting: 1,
    Ok: 2,
    Error: 3,
    Stopping: 4,
    Stopped: 5,
    Disabled: 6
}

export const getMonitorStateText = (monitorstate) => {

    switch (monitorstate) {
        case MonitorState.None:
            return 'None';
        case MonitorState.Starting:
            return 'Starting';
        case MonitorState.Ok:
            return 'Ok';
        case MonitorState.Error:
            return 'Error';
        case MonitorState.Stopping:
            return 'Stopping';
        case MonitorState.Stopped:
            return 'Stopped';
        case MonitorState.Disabled:
            return 'Disabled';
        default:
            return MonitorState.toString();
    }
}

export const getMonitorStateDropdownOptions = () => {

    let options = [];
    for (var property in MonitorState)
        options.push({
            key: 'monitorstate-' + property,
            text: getMonitorStateText(MonitorState[property]),
            value: MonitorState[property]
        });

    return options;

}

class EditSystemMonitorForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            edititem: undefined,
            Error: ""
        };

        this.getSystemMonitor = this.getSystemMonitor.bind(this);
        this.formatStringDate = this.formatStringDate.bind(this);

    }

    componentDidMount() {
        this.getSystemMonitor();
    }

    getSystemMonitor() {
        //e.preventDefault();

        let self = this;

        fetch(configuration.ROOT_URL + '/SystemMonitor/' + this.props.Id, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then(function (data) {

            console.log("Success:", data);
            //self.setState({ edititem: data });
            self.setState((state, props) => {
                return { edititem: data };
            }, () => {
                self.props.UpdateCallback(self.state.edititem);
            });

        }).catch(function (error) {
            console.log("Virhe:", error);
        });
    }

    formatStringDate(datestr) {
        try {
            var date = new Date(datestr);

            return date.toDateString();
        } catch (error) {
            return datestr;
        }
    }

    defaultDate = (d) =>
        d === null ? moment().format("YYYY-MM-DD") : moment(d).format("YYYY-MM-DD");

    onchange = (key, value) => {
        this.setState((state, props) => {
            return { edititem: { ...state.edititem, [key]: value } };
        }, () => {
            this.props.UpdateCallback(this.state.edititem);
        });
    }

    handleChange = (e, data) => {
        e.persist();
        console.log(e);
        this.setState((state, props) => {
            return { edititem: { ...state.edititem, [e.target.name]: e.target.value } };
        }, () => {
            this.props.UpdateCallback(this.state.edititem);
        });
    };

    putFixed = () => {
        try {
            var loginResultString = window.localStorage.getItem(configuration.localStorageKey);

            var loginResultJson = window.JSON.parse(loginResultString);

            console.log(loginResultJson); //?

            console.log(loginResultJson.user); //?

            this.setState((state, props) => {
                return { edititem: { ...state.edititem, 'FixedBy': loginResultJson.UserName, 'TimeFixed': new Date(), 'EmailSentRecipients': null, 'TimeEmailSent': '0001-01-01T00:00:00.000000+00:00' } };
            }, () => {
                this.props.UpdateCallback(this.state.edititem);
            });

        } catch (error) {
            console.log("putFixed Error:" + error);
        }
    }

    getFixedText = () => {
        try {
            if (this.state.edititem == null || this.state.edititem == undefined) return "";

            let retval = this.state.edititem.TimeFixed + "/" + this.state.edititem.FixedBy

            return retval;

    } catch(error) {
        return "";
    }
}

render() {

    return (
        <Form error={this.props.Error !== ""}>
            <Grid columns={1}>
                <Grid.Column>
                    <Segment raised>

                        <Form.Checkbox
                            inline
                            label="Enabled"
                            name="Enabled"
                            checked={this.state.edititem && this.state.edititem.Enabled}
                            onChange={(e, { checked }) => this.onchange('Enabled', checked)}
                        />
                        <Form.Input
                            className="borderless"
                            readOnly
                            inline
                            fluid
                            label="Id:"
                            placeholder="Id"
                            name="Id"
                            value={!!this.state.edititem ? this.state.edititem.Id : ""}
                        />

                        <Form.Select
                            label="Status:"
                            name="Status"
                            options={getMonitorStateDropdownOptions()}
                            value={this.state.edititem && this.state.edititem.Status}
                            onChange={(e, { value }) => this.onchange('Status', value)}
                        >
                            {/* {ItemTrackingStatusConst.map(status => (
                                        <option key={status.id} value={status.name}>
                                            {status.name}
                                        </option>
                                    ))} */}
                        </Form.Select>

                        <Form.Input
                            inline
                            fluid
                            label="SubSystem:"
                            placeholder="SubSystem"
                            name="SubSystem"
                            value={!!this.state.edititem ? this.state.edititem.SubSystem : ""}
                            onChange={this.handleChange}
                        />

                        <Form.Input
                            inline
                            fluid
                            label="Description:"
                            placeholder="Description"
                            name="Description"
                            value={!!this.state.edititem ? this.state.edititem.Description : ""}
                            onChange={this.handleChange}
                        />

                        <Form.Input
                            inline
                            fluid
                            label="ExternalReference:"
                            placeholder="ExternalReference"
                            name="ExternalReference"
                            value={!!this.state.edititem ? this.state.edititem.ExternalReference : ""}
                            onChange={this.handleChange}
                        />

                        <Form.Button color="green" onClick={() => this.putFixed()} >Fixed</Form.Button>
                        <Label>{this.getFixedText()}</Label>
                        <br></br>
                        <Form.Input
                            inline
                            fluid
                            label="FixedComment:"
                            placeholder="FixedComment"
                            name="FixedComment"
                            value={!!this.state.edititem ? this.state.edititem.FixedComment : ""}
                            onChange={this.handleChange}
                        />

                    </Segment>
                </Grid.Column>
            </Grid>
            <Message
                error
                header="Error saving systemmonitor"
                content={this.props.Error}
            />
        </Form>
    );
}
}

export default EditSystemMonitorForm;
import React, { Component } from 'react';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message';
import {version,description,author} from '../package.json';
import PropTypes from 'prop-types';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.submitLogin = this.submitLogin.bind(this);
    }
    submitLogin(e){
        e.preventDefault();
        const {login } = this.props;
        this.uname = document.getElementById('uname');
        this.pwd = document.getElementById('pwd');
        login(this.uname.value, this.pwd.value);
    }
    render() {
        const { loading, error } = this.props;
        
        return (
        <React.Fragment>
            <Form size='large' onSubmit={this.submitLogin}>
                <Segment>
                    <Form.Input
                        fluid
                        required
                        name='username'
                        autoComplete='username'
                        icon='user'
                        id='uname'
                        iconPosition='left'
                        placeholder='Käyttäjänimi'
                    />
                    <Form.Input
                        fluid
                        required
                        name='password'
                        icon='lock'
                        id='pwd'
                        iconPosition='left'
                        placeholder='Salasana'
                        type='password'
                        autoComplete='current-password'
                    />
                    <Button fluid size='large' type='submit' color='google plus' loading={loading} >Kirjaudu</Button>
                    
                    {(error && error !== '') && <Message color='red'>{error}</Message>}
                </Segment>
            </Form>
            
            <br />
                {description + " [ v" + version + " ]"}
                <br />
                {author}<br />
            </React.Fragment>
        );
    }
}
LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    loading: PropTypes.bool
};
  
export default LoginForm;

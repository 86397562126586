import React, { Component } from 'react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';
//import logo from '../images/finnidlogo.svg';
import ApiCall from './api/ApiCall';
import History from './helpers/History';
import LoginForm from './LoginForm';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error:''
        }
        
    }
  
  
    submitLogin = async (username,password) => {
        this.setState({ loading: true}, async () => {
                
            let result = await new ApiCall().login(username,password);
            if(typeof result === "string"){
                console.error(result);
                this.setState({ error: "Sisäänkirjautuminen epäonnistui", loading:false });
            }
            else {
                let toUrl = result && result.Roles && result.Roles.includes("Admin")  ? "/" : "/";
                this.setState({ loading: false, error:'' }, () => History.push(toUrl)); //Success, move to selfservice
            }
            
        });
    }

    render() {
        const { loading, error } = this.state;
        let innerHeight = window.innerHeight - 50;
        return (
            <div style={{ height: innerHeight + 'px'}} >
            
                <Grid textAlign='center' style={{ height: 'inherit' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <LoginForm login={this.submitLogin} loading={loading} error={error} />
                    </Grid.Column>
                </Grid>
            </div>
        );

    }
}
export default Login;
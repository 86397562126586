import fetch from 'cross-fetch';
import configuration from './../configuration';
import History from '../helpers/History';

/***
 * HTTP-METHODS
 ***/
export const httpMethods = {
    POST:"POST",
    PUT:"PUT",
    GET:"GET",
    DELETE:"DELETE",
    PATCH:"PATCH"
};


/**
 * Add parameters to query
 * @param {*} url 
 * @param {*} query 
 */
export function constructQueryUrl(url,query){

    let queryUrl = url.endsWith('/') ? url : url += '/';
   
    if(query === undefined || query === null)
        return queryUrl;
        
    /** if url parameters /[parameter1]/[parameter2]/ */
    if(query.urlparameters){
        
        for (let key in query.urlparameters) {
            queryUrl += window.encodeURIComponent(query.urlparameters[key]) + '/';
        }
    }
    /** inline queryparameters /?parameter1=value1&parameter2=value2 */
    if(query.queryparameters){
        queryUrl += '?';
        
        for (let key in query.queryparameters) {
            queryUrl += (queryUrl.endsWith('?') ? '' : '&') + `${window.encodeURIComponent(key)}=${window.encodeURIComponent(query.queryparameters[key])}`;
        }
        
    }
    return queryUrl;
}

class ApiCall {
    constructor(disablecache){
        this.disablecache = disablecache;
    }
    /***
     * Call http request
     */
    async call(method,url,body, options){

        this.requestOptions = options ? options : {
            method: method,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', ...(this.disablecache ? {'pragma':'no-cache', 'cache-control':'no-cache'} : {}) },
            credentials: 'include'
        };

        if(body !== null && body !== undefined)
        {
            this.requestOptions = {...this.requestOptions, body:body};
        }
        this.method = method;
        this.url = url;
        
        return fetch(url, this.requestOptions)
            .then(this.handleResponse, this.handleError)
            .then(response => { return response; });
    }
    /***
     * Logout
     */
    async logout(){
        try{
            let url = configuration.AUTH_URL;
            if(url.endsWith("/") === false)
                url += "/";

            //No need to store result, it will be in cookie, automatically in every request
            let result = await this.get(url + "logout" );
            
            return result;
        }
        catch(e){
            let message= e.message.startsWith('401') || e.message.startsWith('403') ? '' : e.message;
            console.warn('Logout failed', e);

            //Move to login, if not allready in loginpage
            if(History && History.location && History.location.pathname !== "/login")
                History.push("/login"); //Move to login

            return 'Uloskirjautuminen epäonnistui ' + message;
            
        }
    }
    /***
     * Login
     */
    async login(username,password){

        try{
            let url = configuration.AUTH_URL;

            //No need to store result, it will be in cookie, automatically in every request
            let result = await this.post(url, {username,password} );
            //Save to localstorage
            window.localStorage.setItem(configuration.localStorageKey,window.JSON.stringify(result));
            return result;
        }
        catch(e){
            let message= e.message.startsWith('401') || e.message.startsWith('403') ? '' : e.message;
            console.warn('Login failed', e);

            
            //Move to login, if not allready in loginpage
            if(History && History.location && History.location.pathname !== "/login")
                History.push("/login"); //Move to login

            return 'Kirjautuminen epäonnistui ' + message;
        }
    }
    /**
     * Get all by url
     * @param {*} url 
     */
    get(url){
        return this.call(httpMethods.GET,url);
    }
    /**
     * Get one by id, id is added to url
     * @param {*} id 
     * @param {*} url 
     */
    getbyid(id,url){
        
        if(url.endsWith('/') === false)
            url += '/';

        return this.call(httpMethods.GET,url + encodeURIComponent(id));
    }
    /***
     * Create object (POST) to url, data is serialized
     */
    create(url,data){
        return this.call(httpMethods.POST,url,JSON.stringify(data));
    }
    /**
     * Overload for post
     * @param {*} url 
     * @param {*} data 
     */
    post(url,data){
        return this.create(url,data);
    }
    /***
     * Update object (PUT) to url, id is added to url and data is serialized
     */
    update(url,id,data){

        let updateurl = url;

        if(updateurl.endsWith('/') === false) updateurl += '/';

        if(id) updateurl = updateurl + encodeURIComponent(id)
    
        return this.call(httpMethods.PUT,updateurl,JSON.stringify(data));
    }
    /**
     * Delete object (DELETE) to url, id is added to url
     * @param {*} url 
     */
    delete(url, id){

        if(url.endsWith('/') === false)
            url += '/';

        return this.call(httpMethods.DELETE,url + encodeURIComponent(id));
    }
    /**
     * Delete object (DELETE) to url, id is added to url
     * @param {*} url 
     */
    deletebyurl(url){

        if(url.endsWith('/') === false)
            url += '/';

        return this.call(httpMethods.DELETE,url);
    }
    /****
     * Handle server response
     ****/
    handleResponse(response) {
        return new Promise((resolve, reject) => {
            if (response.ok) {

                // return json if it was returned in the response
                var contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    response.json().then(json => resolve(json));
                } else {
                    resolve(response.text());
                }
            } 
            else if(response.status === 401 || response.status === 403){
                //Move to login, every time 401 'Unauthorized' or 403 'Forbidden' status is received in any request
                reject(new Error(response.status + ": Unauthorized"));
                
                //Move to login, if not allready in loginpage
                if(History && History.location && History.location.pathname !== "/login")
                    History.push("/login"); //Move to login automatically
            }
            else {
                // return error message from response body
                response.text().then(text => {

                    if(text)
                        text = text.toLowerCase();
                        
                    reject(new Error(response.status + ": " + text));
                });
            }
        });
    }
    /***
     * Handle server response error
     */
    handleError(error) {
        
        return new Promise((resolve, reject) => {
            
            reject(error);
        })
    }

}

export default ApiCall;